import React from 'react';
import lightswitch from '../../../images/icons/ic-lightswitch.svg';
import ContestSegmentsMap from '../../../src/js/contests/segments';
import Item from './Item';
import closeIcon from '../../../images/icons/ic-exit.svg';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import ExpandedContest from '../ExpandedContest';

class ContestShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      segmentView: 'list',
      expandMap: false,
    };

    this.expandedMapRef = React.createRef();
  }

  componentDidMount() {
    // console.log({state: this.state});
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.segmentView !== prevState.segmentView) {
      console.log(
        'segmentView changed',
        this.state.segmentView,
        prevState.segmentView
      );
      // Segment view changed
      if (this.state.segmentView === 'map') {
        this.mapHandler = new ContestSegmentsMap({
          mapSelector: 'contest-map',
          expandMapEnabled: true,
          expandMapCallback: () => {
            this.setState({ expandMap: true }, () => {
              disableBodyScroll(this.expandedMapRef.current);
            });
          },
          seeCurrentLocation: true,
          mapProvider: 'mapbox',
        });
        this.mapHandler.addSegmentsToMap(this.state.segments);
      } else {
        this.mapHandler.unmount();
        this.mapHandler = null;
      }
    }
  }

  componentWillUnmount() {
    if (this.mapHandler) {
      this.mapHandler.unmount();
    }
  }

  toggleView = (e) => {
    const view =
      e.currentTarget.dataset.view ||
      (this.state.segmentView === 'list' ? 'map' : 'list');
    this.setState({ segmentView: view });
  };

  closeMap = () => {
    this.setState({ expandMap: false });
    clearAllBodyScrollLocks();
  };

  render() {
    const { segments, segmentView, expandMap } = this.state;

    return (
      <div className={`w-full h-full flex flex-col`}>
        <div className='flex items-start justify-between w-full'>
          <h2 className='mini-label font-medium mb-4'>Segments</h2>
          <div className='flex items-center'>
            <button
              onClick={this.toggleView}
              data-view='list'
              className={`mini-label ${
                segmentView === 'list' ? 'font-medium' : ''
              }`}
            >
              List View
            </button>
            <button onClick={this.toggleView}>
              <img
                src={lightswitch}
                className={`mx-3 w-7 ${
                  segmentView === 'list' ? '' : 'rotate-180'
                }`}
              />
            </button>
            <button
              onClick={this.toggleView}
              data-view='map'
              className={`mini-label ${
                segmentView === 'map' ? 'font-medium' : ''
              }`}
            >
              Map View
            </button>
          </div>
        </div>

        {segmentView === 'list' && (
          <div className='flex-1 min-h-0 overflow-y-auto border-t border-border-secondary'>
            {segments.map((segment, index) => {
              return <Item key={segment.id} segment={segment} index={index} />;
            })}
          </div>
        )}

        {segmentView === 'map' && (
          <div
            className='h-[70vh] xl:h-auto xl:flex-1 rounded map'
            id='contest-map'
          ></div>
        )}

        {expandMap && (
          <div className='fixed top-0 left-0 bottom-0 right-0 bg-background-secondary bg-opacity-60 z-[1000] flex p-4 md:p-8'>
            <div className='bg-background-quaternary rounded border border-border-darker-yellow mx-auto w-full xl:max-h-full py-4 lg:py-8 flex flex-col'>
              <div className='flex justify-between items-start px-4 lg:px-8'>
                <h2 className='mini-label font-medium'>Segments & Locations</h2>
                <button
                  type='button'
                  className='h-auto small-link-text text-text-grey-600'
                  onClick={this.closeMap}
                >
                  Close
                  <img src={closeIcon} className='inline-block ml-2' />
                </button>
              </div>
              <div
                ref={this.expandedMapRef}
                className='w-full flex-1 overflow-y-auto px-4 lg:px-8'
              >
                <ExpandedContest segments={segments} />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default ContestShow;
